@import "@angular/material/prebuilt-themes/indigo-pink.css";
.mat-mdc-dialog-actions {
  justify-content: center !important;
}
h1,
h2,
h3,
h4 {
  display: block;
  margin: 0;
}
.description-box {
  color: white;
  height: auto;
  width: 25%;
  padding-left: 30px;
  font-size: large;
}
.mdc-text-field {
  padding: 0 10px !important;
}
.mat-column-featureName {
  border-right: 1px solid black;
}
.mat-mdc-table-sticky {
  border-right: 1px solid black;
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0 6px 0 6px !important;
  border-bottom-width: 0 !important;
  border-bottom-style: none !important;
}
.mdc-data-table__header-cell {
  text-align: center !important;
}
.mat-mdc-table-sticky-border-elem-right {
  border-left: 1px solid black !important;
}

.mat-mdc-table-sticky-border-elem-left {
  border-right: 1px solid black !important;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: red;
  --mdc-protected-button-label-text-color: #fff;
}
.text-center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.page-container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/assets/img/background.webp");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 15px;
  flex-direction: column;
  margin-bottom: 30px;
}
.embed-page-container {
  background-color: white;
  border-radius: 8px;
  margin: 20px;
  padding: 20px;
}
